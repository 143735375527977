
/**
 * @namespace Global namespace
 */
var revide = {};

;(function($) {
    /**
    * @namespace Global main
    */
    revide.main = (function() {
        return function() {
            var pub = {},
                defaults = {
                    menu: {
                        scrollDownOffset: 100,
                        scrollUpOffset: 150
                    },
                    scrollTo: {
                        offset: 0,
                        animationSpeed: 900
                    }
                },
                screenSize,
                elementSelectors = {
                    navMobile: 'header.mobile',
                    navDesktop: 'header.desktop'
                },
                $elements = {}

            // ******************************
            // PUBLIC METHODS
            // ******************************

            pub.init = function() {
                setupElements();
                delegateEvents();

                if ($(document).width() > 1024) {
                    $(window).on('scroll', function () {
                        scrollSpy();
                    });
                }

                // Run match height script
                pub.matchHeights();
            };

            // ******************************
            // PRIVATE METHODS
            // ******************************

            var scrollSpy = function () {

            };

            var setupElements = function () {
                $.each(elementSelectors, function (el, selector) {
                    $elements[el] = $(selector);
                });
            };

            var delegateEvents = function () {
                
                // Scroll to element with id
                $('a[href^="#"]').on('click', function (e) {
                    e.preventDefault();
                    var target = this.hash,
                        $target = $(target);

                    $('html, body').stop().animate({
                        'scrollTop': $target.offset().top + defaults.scrollTo.offset
                    }, defaults.scrollTo.animationSpeed, 'swing');
                });

            };


            // Matchheight scripts
            pub.matchHeights = function () {
                //$('section.news-start .columns').matchHeight();
            };

            return pub;
        }();

    })();

    $(document).ready(revide.main.init);

    $(window).resize(function() {
        revide.main.matchHeights();
    });

    $('.burger-container').click(function (e) {
        $('header').toggleClass('menu-opened');
    });


    $('#btn-booking').click(function () {
        $('.content-bg iframe').fadeIn("slow");
        $('div.close').fadeIn("slow");
    });

    $('#btn-close-booking').click(function () {
        $('.content-bg iframe').fadeOut('slow');
        $('div.close').fadeOut('slow');
    });


})(jQuery);


// Used for setting browser specific css with SASS mixin
document.documentElement.setAttribute("data-browser", navigator.userAgent);